export const projectsData = [
    {
        title: "Flight Searcher",
        catagory: "Full stack",
        year: "2020",
        frameworks: "Ionic Angular · Python · Flask · MongoDB",
        background:
            "This project is part of Advanced Computer Programming Lab during my 2nd year in the university. The professor assigned us to create a mobile application that make use of Ionic Angular and MongoDB using Python Flask for backend API within the timeframe of two weeks.",
        features:
            "The app performs similarly to other flight searchers with limited functionalities because of time constraint. It gives users an ability to search and compare price directly within the app, and it also let users book the flight via external deeplink. Skyscanner API is used to serve as a real-time flight database, while Google Maps Search API is for the city search part.",
        photos: [
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631120127/images/projects/flight-searcher/1_home_y5ykeo.png",
                description: "Home Page",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631120127/images/projects/flight-searcher/2_search_nqtqr2.png",
                description: "Search Page",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631120127/images/projects/flight-searcher/3_locale_changer_poe7dq.png",
                description: "Locale Changer",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631120127/images/projects/flight-searcher/4_city_description_ut9ao1.png",
                description: "City Desscription Page",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631120127/images/projects/flight-searcher/5_flight_preference_editor_peamp5.png",
                description: "Flight Preference Editor",
            },
        ],
    },
    {
        title: "COVID-19",
        catagory: "Full stack",
        year: "2020",
        frameworks: "React Native · Python · Flask · MongoDB",
        background:
            "During the COVID-19 break, I’ve decided to learn a new mobile cross-platform frontend language: React Native. Using this project as a playground, I have learnt a lot, and later adopted it as my most desired language for writing mobile application.",
        features:
            "As mentioned, I used this project as my playground to discover features the language provide. Therefore, there’s not much going on in this application. I wrote a backend using Python and Flask, which receives data from ThaiMOPH using Twitter Developer API. The app itself receives COVID-19 infection data from external open-source APIs. The app changes language regarding the locale set in the phone – this is written using I18n library.",
        photos: [
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631121728/images/projects/covid19/1_home_bqiqp2.png",
                description: "Home Page",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631121729/images/projects/covid19/2_update_from_twitter_kxltoe.png",
                description: "Updates from @ThaiMOPH Twitter",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631121728/images/projects/covid19/3_worldwide_cases_t0aeaj.png",
                description: "Worldwide Cases",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631121728/images/projects/covid19/4_thailand_cases_ulbicr.png",
                description: "Thailand Cases",
            },
        ],
    },
    {
        title: "Car Service Appointment",
        catagory: "Frontend · UI Design",
        year: "2021",
        frameworks: "React Native",
        background:
            "This group project is part of Technology and Engineering Management subject during my 2nd year study. I was assigned to do the prototype of a car service appointment application; simply say, I mainly do the design prototype of this application.",
        features: "",
        photos: [
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631121937/images/projects/car-service-appointment/1_home_lma6ce.png",
                description: "Home Page",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631121937/images/projects/car-service-appointment/2_service_progress_qsoj4w.png",
                description: "Home Page",
            },
            {
                src: "https://res.cloudinary.com/ktns/image/upload/v1631121937/images/projects/car-service-appointment/3_book_appointment_eviok0.png",
                description: "Home Page",
            },
        ],
    },
    {
        title: "Kafka and LINE OA",
        catagory: "Backend",
        year: "2021",
        frameworks: "Golang",
        background:
            "During my internship at Kasikorn LINE, I have been assigned a project regarding Apache Kafka and LINE Official Account. The objective is to make the interns familiarise with Golang and the structure of the company, which will benefit in working in other projects assigned later.",
        features:
            "The project involves consuming data from Apache Kafka server, analysing it, sending the data through LINE Official Account using LINE Messaging API, then produce the debug JSON back to the Kafka server.",
        photos: [],
    },
];
